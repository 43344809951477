import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { signIn } from '../Firebase/auth';
import Input from '../Components/Forms/IconInput';
import LoadingButton from '../Components/Buttons/LoadingButton';
import TextButton from '../Components/Buttons/TextButton';
import FlashMessage, { useFlashMessage } from '../Components/FlashMessage/FlashMessage';

import logo from '../Assets/icon-white.png';
import useIsMounted from '../Hooks/useIsMounted';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding-bottom: 20px;
`;

const AuthForm = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const LogoWrapper = styled.div`
  background: #34ac8c;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 20px;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
`;

const SignInScreen = () => {
  const history = useHistory();
  const mounted = useIsMounted();
  const flash = useFlashMessage();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if(e.key === 'Enter'){
      handleSubmit();
    }
  }

  const handleSubmit = async () => {
    setLoading(true);
    if(email !== '' && password !== ''){
      const res = await signIn(email, password);
      if(res.error){
        flash.showFlashMessage(res.message, true);
      }
    }
    if(mounted){
      setLoading(false);
    }
  }


  return (
    <Container onKeyDown={handleKeyPress}>
      { flash.flashVisible && <FlashMessage {...flash} /> }
      <Top>
        <LogoWrapper>
          <Image src={logo}/>
        </LogoWrapper>
      </Top>
      <h3>Sign In</h3>
      <AuthForm>
        <Input 
          icon='user'
          style={{ width: '100%', boxSizing: 'border-box' }}
          value={email} 
          onChange={setEmail}
          placeholder='email'
        />
        <Input 
          icon='key'
          style={{ width: '100%', boxSizing: 'border-box' }}
          value={password} 
          onChange={setPassword} 
          placeholder='password'
          type='password'
        />
        <LoadingButton 
          loading={loading}
          setLoading={setLoading}
          title='Submit' 
          onClick={handleSubmit}
        />
      </AuthForm>
      <TextButton text='register' onClick={() => history.push('/register')} />
    </Container>
  )
}

export default SignInScreen;