import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import apiKeys from './Config/firebase';
import STATUS from './Constants/authenticationStatus';

import LoginNavigator from './Navigators/LoginNavigator';
import VoucherNavigator from './Navigators/VoucherNavigator';
import VerifyEmailScreen from './Screens/VerifyEmailScreen';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  const [status, setStatus] = useState(STATUS.unauthenticated);

  if (!firebase.apps.length) {
    firebase.initializeApp(apiKeys.firebaseConfig);
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if(user){
        if(user.emailVerified){
          setStatus(STATUS.verified)
        } else {
          setStatus(STATUS.unverified);
        }
      } else {
        setStatus(STATUS.unauthenticated);
      }
    })
  }, []);

  const renderScreen = () => {
    switch(status){
      case STATUS.unauthenticated:
        return <LoginNavigator /> // signin/signup
      case STATUS.unverified:
        return <VerifyEmailScreen /> 
      case STATUS.verified:
        return <VoucherNavigator /> // redeem voucher / voucher redeemed
    }
  }

  return (
    <Router>
      { renderScreen() }
    </Router>
  );
}

export default App;
