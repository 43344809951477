import React from 'react';
import styled from 'styled-components';

import KeyIcon from '@material-ui/icons/VpnKey';
import UserIcon from '@material-ui/icons/Person';

const Container = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: #ddd;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
  width: 100%;
  max-width: 100%;
`;

const Input = styled.input`
  background: inherit;
  border: none;
  padding: 10px;
  font-size: 20px;
  width: 100%;
  box-sizing: border-box;
  flex: 1;

  &:focus {
    outline: none;
  }
`;

interface Props {
  icon: 'key'|'user';
  onChange: (value: any) => void;
  value: string|number;
  type?: 'text'|'password'|'number';
  style?: object;
  placeholder?: string;
}

const IconInput: React.FC<Props> = ({ icon, value, onChange, type, placeholder }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    onChange(type === "number" ? parseInt(value) : value);
  }

  return (
    <Container>
      {
        icon === 'key'
        ? <KeyIcon />
        : <UserIcon />
      }
      <Input 
        onChange={handleChange} 
        value={value} 
        type={type}
        placeholder={placeholder}
      />
    </Container>
  )
}

export default IconInput;