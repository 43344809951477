import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
  margin: 0;
  color: #aaa;
  font-size: 16px;
  margin-bottom: 10px;

  &:active {
    opacity: 0.8;
  }
`;

interface Props {
  style?: any;
  text: string;
  onClick: () => void;
}

const TextButton: React.FC<Props> = ({
  style,
  text,
  onClick
}) => {
  return <Text 
    style={style} 
    onClick={onClick}
  >{ text }</Text>
}

export default TextButton;