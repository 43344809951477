import React from 'react';
import styled from 'styled-components';

const RInput = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  background: #ddd;

  &:focus {
    outline: none;
  }
`;

interface Props {
  onChange: (value: any) => void;
  value: string|number;
  type?: 'text'|'password'|'number';
  style?: object;
  placeholder?: string;
}

const Input: React.FC<Props> = ({
  onChange,
  value,
  type='text',
  style,
  placeholder
}) => {
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    onChange(type === "number" ? parseInt(value) : value);
  }

  return <RInput 
    style={style}
    onChange={handleChange} 
    value={value} 
    type={type}
    placeholder={placeholder}
  />
}

export default Input;