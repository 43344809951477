import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import SignInScreen from '../Screens/SignInScreen';
import SignUpScreen from '../Screens/SignUpScreen';

const LoginNavigator = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={SignInScreen} />
        <Route path='/register' component={SignUpScreen} />
      </Switch>
    </Router>
  )
}

export default LoginNavigator;