import firebase from 'firebase/app';
import 'firebase/firestore';

export async function redeemVoucherCode(code: string){
  try {
    const user = firebase.auth().currentUser;
    const db = firebase.firestore();
    const query = db.collection("credits").where("voucher", "==", code);
    const snapshot = await query.get();
    if(!snapshot.empty){
      const doc = snapshot.docs[0];
      if(doc.data().expired || doc.data().redeemed){
        return {
          error: true,
          message: "Invalid voucher code"
        }
      }

      const ref = db.collection("credits").doc(doc.id);

      await ref.set({
        user: user?.uid,
        redeemed: true,
        redeemedDate: Date.now()
      }, { merge: true });

      return {
        error: false,
        message: 'Voucher successfully redeemed'
      }
    } else {
      return {
        error: true,
        message: "Invalid voucher code"
      }
    }
  } catch(err){
    return {
      error: true,
      message: err.message
    }
  }
}
