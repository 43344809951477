import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Button from '../Components/Buttons/Button';
import { logout } from '../Firebase/auth';
import useCurrentUser from '../Hooks/useCurrentUser';

const Container = styled.div``;

interface Props {

}

const SignInScreen: React.FC<Props> = () => {
  const user = useCurrentUser();
  const history = useHistory();

  const handleSubmit = () => {
    logout();
    history.push('/');
  }

  if (!user) return null;

  return (
    <Container>
      <p>VERIFY EMAIL</p>
      <p>An email has been sent to {user.email}, click on the link provided to verify your email</p>
      <Button title='Continue' onClick={handleSubmit} />
    </Container>
  )
}

export default SignInScreen;