export default function getTint(hex: string, percent: number): string {
  hex = hex.replace(/^\s*#|\s*$/g, "");
  if(hex.length === 3){
    hex = hex.replace(/(.)/, "$1$1");
  }

  const r = parseInt(hex.substr(0,2), 16);
  const g = parseInt(hex.substr(2,2), 16);
  const b = parseInt(hex.substr(4,2), 16);

  return "#" + 
    ((0|(1<<8) + r + (256 - r) * percent / 100).toString(16)).substr(1) +
    ((0|(1<<8) + g + (256 - g) * percent / 100).toString(16)).substr(1) +
    ((0|(1<<8) + b + (256 - b) * percent / 100).toString(16)).substr(1);
}