import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import getTint from '../../Helpers/getTint';

const Container = styled.div<{ color: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.color};
  width: 100%;
  height: 43px;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }

  &: active {
    transform: translateY(2px);
  }
`;

const Text = styled.p<{ color: string}>`
  margin: 0;
  z-index: 1;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  color: ${props => props.color};
`;

const Background = styled.div<{ color: string }>`
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 50%;
  top: 0;
  background: ${props => props.color};
  border-radius: 10px 10px 0 0;
`;

interface Props {
  loading: boolean;
  setLoading: (b: boolean) => void;
  title: string;
  onClick: () => void;
}

const LoadingButton: React.FC<Props> = ({
  loading,
  setLoading,
  onClick,
  title
}) => {
  const handlePress = () => {
    setLoading(true);
    onClick();
  }

  const color = '#34ac8c';
  const tint = getTint(color, 20);

  return (
    <Container onClick={handlePress} color={color}>
      <Background color={tint} />
      {
        loading
        ? <CircularProgress size={24} style={{ color: 'green' }}/>
        : <Text color='#fff'>{ title }</Text>
      }
    </Container>
  )
}

export default LoadingButton;