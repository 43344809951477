import React from 'react';
import styled from 'styled-components';
import getTint from '../../Helpers/getTint';

const Container = styled.div<{ background: string }>`
  position: relative;
  background: ${props => props.background};
  width: 100%;
  z-index: 0;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }

  &: active {
    transform: translateY(2px);
  }
`;

const Text = styled.p<{ color: string; }>`
  color: ${props => props.color};
  text-align: center;
  padding: 10px;
  margin: 0;
  font-weight: bold;
  z-index: 1;
  text-transform: uppercase;
`;

const Background = styled.div<{ color: string }>`
  position: absolute;
  width: 100%;
  height: 50%;
  top: 0;
  z-index: -1;
  background: ${props => props.color};
  border-radius: 10px 10px 0 0;
`;

interface Props {
  title: string;
  onClick: () => void;
}

const Button: React.FC<Props> = ({
  title,
  onClick
}) => {
  const color = '#34ac8c';
  const tint = getTint(color, 20);

  return (
    <Container onClick={onClick} background={color} > 
      <Text color='#fff'>{ title }</Text>
      <Background color={tint} />
    </Container>
  )
}

export default Button;