import React from 'react';
import styled from 'styled-components';
import { logout } from '../../Firebase/auth';

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  margin: 0;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const Text = styled.p`
  font-size: 18px;
  color: #888;
  margin: 0;
`;

const LogoutButton = () => {
  return (
    <Container onClick={logout}>
      <Text>Logout</Text>
    </Container>
  )
}

export default LogoutButton;