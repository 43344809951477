import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Button from '../Components/Buttons/Button';
import useCurrentUser from '../Hooks/useCurrentUser';
import FlashMessage, { useFlashMessage } from '../Components/FlashMessage/FlashMessage';
import { DiAndroid, DiApple } from "react-icons/di";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
`;

const Title = styled.h3``;

const Text = styled.p`
  text-align: center;
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
  font-size: 20px;
  height: 43px;
  width: 100%;
  max-width: 400px;
`;

const AppStoreButtons = styled.div`
  display: flex;
  padding: 20px;
  margin-bottom: 40px;
`;

const AppButton = styled.a`
  background: black;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 0 20px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }
`;

const SuccessScreen = () => {
  const user = useCurrentUser();
  const history = useHistory();
  const flash = useFlashMessage();

  if (!user) return null;

  return (
    <Container>
      { flash.flashVisible && <FlashMessage {...flash} /> }
      <Title>Success!</Title>
      <Text>Voucher has successfully be added to the account <em>{user.email}</em>.</Text>
      <Text>Login to the app at Roamr to play your game.</Text>
      <AppStoreButtons>
        <AppButton href='https://appstore.com/roamr' target='_blank'>
          <DiApple color='white' size={24}/>
        </AppButton>
        <AppButton href='https://play.google.com/store/apps/details?id=com.cityescapegames.app' target='_blank'>
          <DiAndroid color='white' size={24}/>
        </AppButton>
      </AppStoreButtons>
      <ButtonWrapper>
        <Button title='back' onClick={() => history.push('/')} />
      </ButtonWrapper>
    </Container>
  )
}

export default SuccessScreen;