const config = {
  firebaseConfig: {
    apiKey: "AIzaSyAPuW3fVVOe9irs1CBD8qOsOLcJPL6uHQE",
    authDomain: "city-escape-games.firebaseapp.com",
    projectId: "city-escape-games",
    storageBucket: "city-escape-games.appspot.com",
    messagingSenderId: "9861316559",
    appId: "1:9861316559:web:475ef0d354e9bc0eb89cba",
    measurementId: "G-B3NB89TRG8"
  }
};  

export default config;