import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Input from '../Components/Forms/IconInput';
import LoadingButton from '../Components/Buttons/LoadingButton';
import { signUp } from '../Firebase/auth';
import logo from '../Assets/icon-white.png';
import TextButton from '../Components/Buttons/TextButton';
import FlashMessage, { useFlashMessage } from '../Components/FlashMessage/FlashMessage';

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding-bottom: 20px;
`;

const AuthForm = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const LogoWrapper = styled.div`
  background: #34ac8c;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 20px;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
`;
interface Props {

}

const SignUpScreen: React.FC<Props> = () => {
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const flash = useFlashMessage();

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if(e.key === 'Enter'){
      handleSubmit();
    }
  }

  const handleSubmit = async () => {
    setLoading(true);
    if(password1 === password2){
      const res = await signUp(email, password1);
      if(res.error){
        flash.showFlashMessage(res.message, true);
      }
    } else {
      flash.showFlashMessage('Your passwords do not match', true);
    }
    setLoading(false);
  }

  return (
    <Container onKeyDown={handleKeyPress}>
      { flash.flashVisible && <FlashMessage {...flash} /> }
      <Top>
        <LogoWrapper>
          <Image src={logo}/>
        </LogoWrapper>
      </Top>
      <h3>Register</h3>
      <AuthForm>
        <Input 
          icon='user'
          style={{ width: '100%', boxSizing: 'border-box' }}
          value={email} 
          onChange={setEmail}
          placeholder='email'
        />
        <Input 
          icon='key'
          style={{ width: '100%', boxSizing: 'border-box' }}
          value={password1} 
          onChange={setPassword1} 
          placeholder='password'
          type='password'
        />
        <Input 
          icon='key'
          style={{ width: '100%', boxSizing: 'border-box' }}
          value={password2} 
          onChange={setPassword2} 
          placeholder='retype password'
          type='password'
        />
        <LoadingButton
          loading={loading}
          setLoading={setLoading}
          title='Submit' 
          onClick={handleSubmit}
        />
      </AuthForm>
      <TextButton text='register' onClick={() => history.push('/')} />
    </Container>
  )
}

export default SignUpScreen;