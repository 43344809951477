import firebase from 'firebase/app';
import 'firebase/auth';

const useCurrentUser = () => {
  const currentUser = firebase.auth().currentUser;
  if(currentUser){
    const { email, emailVerified } = currentUser;
    return { email, emailVerified };
  }
  return null;
}

export default useCurrentUser;