import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export async function signIn(email: string, password: string): Promise<any> {
  try {
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    return {
      success: true,
      message: "Successfully signed in"
    }
  } catch (err) {
    return {
      error: true,
      message: err.message
    }
  }
}

export async function signUp(email: string, password: string): Promise<{ error: boolean, message: string }> {
  try {
    await firebase.auth().createUserWithEmailAndPassword(email, password);

    const currentUser = firebase.auth().currentUser;
    if(currentUser){
      const db = firebase.firestore();
      await db.collection("users")
      .doc(currentUser.uid)
      .set({
        email: currentUser.email,
        created: Date.now(),
        role: 0
      });
  
      await currentUser.sendEmailVerification();
    }
  
    return {
      error: false,
      message: 'Successfully created'
    }
  } catch (err) {
    console.log(err);
    return {
      error: true,
      message: err.message
    }
  }
}

export async function logout() {
  try {
    await firebase.auth().signOut();
    return {
      success: true,
      message: "Successfully logged out"
    }
  } catch (err) {
    return {
      error: true,
      message: err.message
    }
  }
}
