import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import RedeemVoucherScreen from '../Screens/RedeemVoucherScreen';
import SuccessScreen from '../Screens/SuccessScreen';

const VoucherNavigator = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={RedeemVoucherScreen} />
        <Route path='/success' component={SuccessScreen} />
      </Switch>
    </Router>
  )
}

export default VoucherNavigator;