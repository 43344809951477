import React, { useState } from 'react';
import styled from 'styled-components';
import LoadingButton from '../Components/Buttons/LoadingButton';
import Input from '../Components/Forms/Input';
import LogoutButton from '../Components/Buttons/LogoutButton';
import { redeemVoucherCode } from '../Firebase/credits';
import { useHistory, useLocation } from 'react-router';
import FlashMessage, { useFlashMessage } from '../Components/FlashMessage/FlashMessage';

import backgroundImage from '../Assets/ticket.png';
import useIsMounted from '../Hooks/useIsMounted';
import useWindowDimensions from '../Hooks/useWindowDimensions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
`;

const Title = styled.h3`
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
  margin-top: 20px;
`;

const Text = styled.p`
  padding: 20px;
  padding-bottom: 0;
  margin: 0;
  text-align: center;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
`;

const RedeemForm = styled.div<{width: number}>`
  position: relative;
  width: ${props => props.width > 400 ? 400 : props.width}px;
  height: ${props => props.width > 400 ? 225 : props.width*9/16}px;
`;

const ButtonWrapper = styled.div`
  max-width: 400px;
  width: 100%;
`;

interface Props {

}

const RedeemVoucherScreen: React.FC<Props> = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { width } = useWindowDimensions();
  const query = new URLSearchParams(search);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(query.get('code') || '');
  const mounted = useIsMounted();
  const flash = useFlashMessage();

  const handleSubmit = async () => {
    setLoading(true);
    if(code !== ''){
      const res = await redeemVoucherCode(code);
      if(res.error){
        flash.showFlashMessage(res.message, true);
      } else {
        history.push('/success', { 
          flashMessage: {
            text: 'Successfully added voucher code',
            error: false
          }
        });
      }
    }
    if(mounted){
      setLoading(false);
    }
  }

  return (
    <Container>
      { flash.flashVisible && <FlashMessage {...flash} /> }
      <LogoutButton />
      <div>
        <Title>Redeem Voucher</Title>
        <Text>
          Enter your voucher code below and click 'submit' to add the voucher to your account.
        </Text>
      </div>
      <RedeemForm width={width}>
        <Image src={backgroundImage} />
        <Input 
          value={code} 
          onChange={setCode} 
          style={{ 
            position: 'absolute',
            fontWeight: 'bold',
            left: '31.8%',
            top: '54.2%',
            width: '51.8%',
            height: '27.8%',
            fontSize: 32,
            color: 'white',
            textTransform: 'uppercase',
            textAlign: 'center',
            background: 'transparent',
            boxSizing: 'border-box'
          }}
        />
      </RedeemForm>
      <ButtonWrapper>
        <LoadingButton
          title='submit'
          loading={loading}
          setLoading={setLoading}
          onClick={handleSubmit}
        />
      </ButtonWrapper>
    </Container>
  )
}

export default RedeemVoucherScreen;